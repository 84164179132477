<template>
  <div class="wrapper">
    <CustomForm
      ref="CustomForm"
      v-loading="loading"
      :form-model="formModel"
      :form-items="formItem"
      :form-rules="formRules"
    >
      <UploadImg
        slot="businessLicense"
        slot-scope="{item}"
        :upload-lists="businessLicenseUploadList"
        :pic-disable="formStatus === 'detail'"
        @uploadChange="item.uploadChange"
      />
      <UploadImg
        slot="professionalQualification"
        slot-scope="{item}"
        :upload-lists="professionalQualificationUploadList"
        :pic-disable="formStatus === 'detail'"
        @uploadChange="item.uploadChange"
      />
      <UploadImg
        slot="relatedImgs"
        slot-scope="{item}"
        :upload-lists="relatedImgsUploadList"
        :limit="6"
        :pic-disable="formStatus === 'detail'"
        @uploadChange="item.uploadChange"
      />
      <RegionForm slot="tenant" ref="RegionForm" v-model="formModel.tenant" :disabled="formStatus === 'detail'" :multiple="true" />
      <CustomMap
        slot="customMap"
        ref="CustomMap"
        :lat="formModel.latitude"
        :lng="formModel.longitude"
        :disabled="formStatus === 'detail'"
        @callback="formItem.customMap.callback"
      />
      <div slot="compAddres">
        <el-input
          v-model="formModel.compAddres"
          type="text"
          :maxlength="255"
          placeholder="请输入详细地址或在地图中选择"
          clearable
          :disabled="formStatus === 'detail'"
        />
        <div style="display: flex; margin-top: 10px;">
          <el-input
            v-model="formModel.latitude"
            type="text"
            placeholder="请在地图中选择位置以获取纬度"
            disabled
            style="margin-right: 5px;"
          />
          <el-input
            v-model="formModel.longitude"
            type="text"
            placeholder="请在地图中选择位置以获取经度"
            disabled
            style="margin-left: 5px;"
          />
        </div>
      </div>
      <div slot="button" style="text-align: center;">
        <el-button v-if="formStatus !== 'detail'" type="primary" icon="fa fa-check" @click="handleSubmit">保存</el-button>
        <el-button icon="fa fa-undo" @click="back">返回</el-button>
      </div>
    </CustomForm>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import RegionForm from '@/components/RegionForm.vue'
import CustomForm from '@/components/CustomForm/index'
import CustomMap from '@/components/CustomMap/index'

export default {
  name: 'AddOrEdit',
  components: {
    CustomMap,
    CustomForm,
    UploadImg,
    RegionForm
  },
  data() {
    return {
      loading: true,
      dict: {
        company_type: []
      },
      businessLicenseUploadList: [],
      professionalQualificationUploadList: [],
      relatedImgsUploadList: [],
      formModel: {
        businessLicense: '',
        compAddres: '',
        compName: '',
        companyType: '',
        contPerson: '',
        contPhone: '',
        emptyBedNum: 0,
        inOldPersonCnt: 0,
        introduction: '',
        latitude: 0,
        longitude: 0,
        professionalQualification: '',
        relatedImgs: '',
        sigDate: [],
        sigFromDate: '',
        sigToDate: '',
        tenant: []
      }
    }
  },
  computed: {
    formId() {
      return this.$route.params.id
    },
    formStatus() {
      return this.$route.params.type
    },
    itemData() {
      return JSON.parse(this.$route.query.item)
    },
    formItem() {
      return {
        'compName': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '名称',
          'maxlength': 255,
          'disabled': this.formStatus === 'detail'
        },
        'companyType': {
          'elColSpan': 12,
          'component': 'CustomFormSelect',
          'label': '类型',
          'dataField': {
            'label': 'dictDesc',
            'value': 'dictPidVal'
          },
          'data': this.dict.company_type,
          'disabled': this.formStatus === 'detail'
        },
        'introduction': {
          'elColSpan': 24,
          'component': 'CustomFormTextarea',
          'label': '简介',
          'maxlength': 1000,
          'showWordLimit': true,
          'disabled': this.formStatus === 'detail'
        },
        'contPerson': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '联系人',
          'maxlength': 100,
          'disabled': this.formStatus === 'detail'
        },
        'contPhone': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '联系方式',
          'maxlength': 100,
          'disabled': this.formStatus === 'detail'
        },
        // 'emptyBedNum': {
        //   'elColSpan': 12,
        //   'component': 'CustomFormNumber',
        //   'label': '空闲床位数量',
        //   'min': 0,
        //   'max': 99999999,
        //   'precision': 0,
        //   'step': 10,
        //   'controls': true,
        //   'disabled': this.formStatus === 'detail'
        // },
        // 'inOldPersonCnt': {
        //   'elColSpan': 12,
        //   'component': 'CustomFormNumber',
        //   'label': '入住老人数量',
        //   'min': 0,
        //   'max': 99999999,
        //   'precision': 0,
        //   'step': 10,
        //   'controls': true,
        //   'disabled': this.formStatus === 'detail'
        // },
        'sigDate': {
          'elColSpan': 24,
          'component': 'CustomFormDatePicker',
          'label': '签约日期',
          'type': 'daterange',
          'valueFormat': 'yyyy-MM-dd',
          'disabled': this.formStatus === 'detail'
        },
        'businessLicense': {
          'elColSpan': 12,
          'label': '营业执照',
          'slot': 'businessLicense',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'businessLicense', data[0])
            } else {
              this.$set(this.formModel, 'businessLicense', '')
            }
          }
        },
        'professionalQualification': {
          'elColSpan': 12,
          'label': '从业资质',
          'slot': 'professionalQualification',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'professionalQualification', data[0])
            } else {
              this.$set(this.formModel, 'professionalQualification', '')
            }
          }
        },
        'relatedImgs': {
          'elColSpan': 24,
          'label': '相关图片',
          'slot': 'relatedImgs',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'relatedImgs', data.join(','))
            } else {
              this.$set(this.formModel, 'relatedImgs', '')
            }
          }
        },
        'tenant': {
          'elColSpan': 24,
          'label': '服务区域',
          'slot': 'tenant'
        },
        'compAddres': {
          'elColSpan': 24,
          'label': '地址信息',
          'slot': 'compAddres'
        },
        'customMap': {
          'elColSpan': 24,
          'slot': 'customMap',
          'callback': data => {
            this.formModel.compAddres = data.poiaddress
            this.formModel.latitude = data.latlng.lat
            this.formModel.longitude = data.latlng.lng
          }
        },
        'button': {
          'elColSpan': 24,
          'slot': 'button'
        }
      }
    },
    formRules() {
      return {
        compName: [
          { required: true, message: '名称不能为空', trigger: ['blur', 'change'] }
        ],
        companyType: [
          { required: true, message: '类型不能为空', trigger: ['blur', 'change'] }
        ],
        contPerson: [
          { required: true, message: '联系人不能为空', trigger: ['blur', 'change'] }
        ],
        contPhone: [
          { required: true, message: '联系方式不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value && !this.$regex.telRegEx(value)) {
                callback(new Error('联系方式格式不正确'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        // emptyBedNum: [
        //   { required: true, message: '空闲床位数量不能为空', trigger: ['blur', 'change'] }
        // ],
        // inOldPersonCnt: [
        //   { required: true, message: '入住老人数量不能为空', trigger: ['blur', 'change'] }
        // ],
        sigDate: [
          { required: true, message: '签约日期不能为空', trigger: ['blur', 'change'] }
        ],
        tenant: [
          { required: true, message: '服务区域不能为空', trigger: ['blur', 'change'] }
        ],
        compAddres: [
          { required: true, message: '地址信息不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (!this.formModel.latitude || !this.formModel.longitude) {
                callback(new Error('坐标信息不能为空'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  created() {
    this.getSysDictList('company_type')
    this.initData().then(() => {
      this.$nextTick(() => {
        this.$refs.CustomMap.init()
        this.$refs.RegionForm.initRegion()
      })
      this.$refs.CustomForm.$refs.form.clearValidate()
      this.closeLoading()
    })
  },
  methods: {
    ...mapActions(['downloadFile', 'getSysDictServe', 'addServeOrgsServe', 'updateOrgsServe']),
    closeLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    back() {
      this.$router.go(-1)
    },
    getSysDictList(typeCode) {
      this.getSysDictServe({ typeCode }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.dict[typeCode] = res.data
      })
    },
    initData() {
      return new Promise(resolve => {
        if (this.formStatus !== 'add') {
          const itemData = { ...this.itemData }

          this.getFileDetail(itemData.businessLicense, 'businessLicenseUploadList')
          this.getFileDetail(itemData.professionalQualification, 'professionalQualificationUploadList')
          this.getFileDetail(itemData.relatedImgs, 'relatedImgsUploadList')

          this.formModel.id = this.formId
          this.formModel.businessLicense = itemData.businessLicense
          this.formModel.compAddres = itemData.compAddres
          this.formModel.compName = itemData.compName
          this.formModel.companyType = itemData.companyType
          this.formModel.contPerson = itemData.contPerson
          this.formModel.contPhone = itemData.contPhone
          this.formModel.emptyBedNum = itemData.emptyBedNum
          this.formModel.inOldPersonCnt = itemData.inOldPersonCnt
          this.formModel.introduction = itemData.introduction
          this.formModel.latitude = itemData.latitude
          this.formModel.longitude = itemData.longitude
          this.formModel.professionalQualification = itemData.professionalQualification
          this.formModel.relatedImgs = itemData.relatedImgs
          this.formModel.sigDate = itemData.sigFromDate && itemData.sigToDate ? [itemData.sigFromDate, itemData.sigToDate] : []
          this.formModel.sigFromDate = itemData.sigFromDate
          this.formModel.sigToDate = itemData.sigToDate
          this.formModel.tenant = itemData.tenants.split('|').map((item) => {
            return item.split(',').slice(2)
          })
          resolve()
        } else {
          resolve()
        }
      })
    },
    getFileDetail(ids, field) {
      if (!ids) {
        return
      }
      ids = ids.split(',')
      if (ids.length === 0) {
        return
      }
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this[field].push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    handleSubmit() {
      this.loading = true
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          const params = { ...this.formModel }
          params.tenant = params.tenant.map((item) => {
            return `1,101,${item.join(',')}`
          })
          params.sigFromDate = params.sigDate[0]
          params.sigToDate = params.sigDate[1]
          delete params.sigDate

          switch (this.formStatus) {
            case 'add':
              delete params.id
              this.addServeOrgsServe(params).then(res => {
                if (res.code !== 200) {
                  this.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('添加成功')
                this.back()
              }).catch(() => {
                this.closeLoading()
              })
              break
            case 'edit':
              this.updateOrgsServe(params).then(res => {
                if (res.code !== 200) {
                  this.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('修改成功')
                this.back()
              }).catch(() => {
                this.closeLoading()
              })
              break
            default:
              this.closeLoading()
              break
          }
        } else {
          this.closeLoading()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    overflow: auto;
  }
</style>
